import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Breadcrumb from '../components/breadcrumb'
import CalendarBlog from '../icons/calendar-blog'
import ReactPaginate from "react-paginate"

class BlogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            itemsPerPage: 6,
        }
    }

    handlePageChange = ({ selected }) => {
        this.setState({ currentPage: selected })
    }

    render() {
        const posts = this.props.data.allContentfulBlog.edges
        const { currentPage, itemsPerPage } = this.state
        const indexOfLastPost = (currentPage + 1) * itemsPerPage
        const indexOfFirstPost = indexOfLastPost - itemsPerPage
        const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

        return (
            <div>
                <Layout>
                    <Seo
                        title="Automotive Service Blog: Expert Tips for Seat Belt Repair, Battery Cable Repair, and More"
                        description='Explore our informative blog on post-collision automotive services. Discover expert advice on seat belt repair, battery cable repair, steering column sensor repair, and more.'
                        keywords={[
                            "Seat Belt Repair",
                            "Seat Belt Coloring",
                            "Battery Cable Repair",
                            "Steering Column Sensor Repair",
                            "SRS Airbag Module Repair",
                            "Convertible Roll Bar Repair"
                        ]}
                        location="/blog"
                        isBlogPage={true}
                        type="BlogPosting"
                    />
                    <div className=''>
                        {/* <div className="bg-white border border-gray-200 shadow-lg">
                            <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/blog', label: 'Blog' },
                                ]} />
                            </nav>
                        </div> */}
                        <div className="container mx-auto py-7 lg:py-8 px-10 pb-9 lg:pb-12">
                            <div className="pt-5 text-center lg:text-left mx-auto">
                                <div className="">
                                    <nav className="md:-ml-5 py-3 mx-auto flex justify-center lg:justify-start" aria-label="Breadcrumb">
                                        <Breadcrumb links={[
                                            { to: '/', label: 'Home' },
                                            { to: '/blog', label: 'Blog' },
                                        ]} />
                                    </nav>
                                </div>
                                <h1 className="font-bold text-3xl md:text-4xl py-5 mx-auto">
                                    L&D Solutions Blog
                                </h1>
                                {/* <p className="py-2 text-sm md:text-base max-w-xl mx-auto">Find the latest news, insights, and resources for all your post collision vehicle needs!</p> */}
                            </div>
                            <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                                {currentPosts.map(({ node: post }) => {
                                    const title = post.title;
                                    const image = getImage(post.image);
                                    let blogCardTitle = post.category + " Blog Post";
                                    return (
                                        <div className="flex flex-col mx-auto mb-3 xl:mb-12" key={post.slug}>
                                            <article>
                                                <div className="max-w-md mx-auto hover:opacity-80 rounded-lg">
                                                    <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                        <GatsbyImage className="h-56 rounded-lg z-0" src="" image={image} title={post.image.title} alt={post.image.description} />
                                                    </Link>
                                                    <div className="px-1 py-5">
                                                        <p className="font-medium text-lndred uppercase text-sm">{post.category}</p>
                                                        <Link to={post.slug} title={blogCardTitle} alt={post.description}>
                                                            <h3 className="pt-3 font-bold text-lg">{title}</h3>
                                                            <p className="py-3 text-base">{post.description}</p>
                                                        </Link>
                                                        <div className="flex space-x-1">
                                                            <CalendarBlog />
                                                            <p className="pb-2 pt-1 text-sm text-gray-500">{post.datePublished}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex justify-center">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"mt-1.5"}
                                    pageCount={Math.ceil(posts.length / itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={this.handlePageChange}
                                    breakLinkClassName="px-1.5 py-1 rounded-full hover:rounded-full hover:bg-lndred transition duration-150 ease-in-out hover:text-white"
                                    containerClassName="flex space-x-2"
                                    activeLinkClassName="px-2 py-1 rounded-full bg-lndred text-white"
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    pageClassName="pt-1"
                                    pageLinkClassName="px-2 py-1 rounded-full hover:bg-lndred hover:rounded-full hover:text-white transition duration-150 ease-in-out"
                                    renderOnZeroPageCount={null}
                                    nextClassName='hover:text-lndred transition duration-150 ease-in-out'
                                    previousClassName='hover:text-lndred transition duration-150 ease-in-out'
                                />
                            </div>
                        </div>

                    </div>
                </Layout>
            </div>
        )
    }
}
export default BlogPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulBlog(sort: { fields: datePublished, order: DESC }) {
        edges{
            node{
                title
                category
                slug
                author
                body {
                    childMarkdownRemark {
                        html
                    }
                }
                description
                datePublished(formatString: "MMMM Do, YYYY")
                image {
                   gatsbyImageData(
                       width: 600
                       placeholder: NONE
                       formats: [AUTO, WEBP, AVIF]
                   )
                   title
                   description
                }
            }
        }
    }
}
`